<!-- <maphilight id="statesMap" #maphilight>
  <img src="../../assets/Ankiros_Hall-2.jpg" #img>

  <map #map>
    <area shape="poly" coords="777,503,840,502,841,637,801,637,802,614,775,614,776,503" alt="State Profile: Washington"
      title="State Profile: Washington" ng-click="clicked('h')">

    <area shape="rect" coords="842,502,916,637" alt="State Profile: Washington" title="State Profile: Washington"
      ng-click="clicked('h')">

  </map>
</maphilight> -->



<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step>
    <maphilight id="statesMap" #maphilight>
      <img src="../../assets/Ankiros_Hall-2.jpg" #img>

      <map #map>
        <area shape="poly" coords="777,503,840,502,841,637,801,637,802,614,775,614,776,503"
          alt="State Profile: Washington" title="State Profile: Washington" ng-click="clicked('h')">

        <area shape="rect" coords="842,502,916,637" alt="State Profile: Washington" title="State Profile: Washington"
          ng-click="clicked('h')">

      </map>
    </maphilight>
  </mat-step>
  <mat-step>
    <maphilight id="statesMap" #maphilight>
      <img src="../../assets/Ankiros_Hall-2.jpg" #img>

      <map #map>
        <area shape="poly" coords="777,503,840,502,841,637,801,637,802,614,775,614,776,503"
          alt="State Profile: Washington" title="State Profile: Washington" ng-click="clicked('h')">

        <area shape="rect" coords="842,502,916,637" alt="State Profile: Washington" title="State Profile: Washington"
          ng-click="clicked('h')">

      </map>
    </maphilight>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    You are now done.
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>