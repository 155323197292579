import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { MaphilightComponent } from 'ng-maphilight';

@Component({
  selector: 'app-interactive-plan',
  templateUrl: './interactive-plan.component.html',
  styleUrls: ['./interactive-plan.component.scss'],
})
export class InteractivePlanComponent implements OnInit {
  @ViewChild(MaphilightComponent, { static: false }) maphilightComponent: MaphilightComponent;
  private _mobile = new BehaviorSubject<boolean>(false)
  mobile$ = this._mobile.asObservable()

  hidden = false
  config = {
    fade: true,
    alwaysOn: false,
    neverOn: false,

    // fill
    fill: true,
    fillColor: '#ffffff',
    fillOpacity: 0.4,

    // stroke
    stroke: true,
    strokeColor: '#4d0ec0',
    strokeOpacity: 1,
    strokeWidth: 1,

    // shadow:
    shadow: true,
    shadowColor: '#000000',
    shadowOpacity: 0.8,
    shadowRadius: 10
  }

  selected = "Main"
  constructor() {
  }

  ngOnInit(): void {
  }

  change(event: any) {
    this.selected = event.value;
  }


  openHall(event: any) {
    this.selected = event;
  }
}
