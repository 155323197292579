import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { MaphilightComponent } from 'ng-maphilight';

@Component({
  selector: 'app-mobile-main',
  templateUrl: './mobile-main.component.html',
  styleUrls: ['./mobile-main.component.scss'],
})
export class MobileMapComponent implements OnInit {

  @ViewChild(MaphilightComponent, { static: false }) maphilightComponent: MaphilightComponent;
  private _mobile = new BehaviorSubject<boolean>(false)
  mobile$ = this._mobile.asObservable()

  hidden = false
  config = {
    fade: true,
    alwaysOn: false,
    neverOn: false,

    // fill
    fill: true,
    fillColor: '#ffffff',
    fillOpacity: 0.4,

    // stroke
    stroke: true,
    strokeColor: '#4d0ec0',
    strokeOpacity: 1,
    strokeWidth: 1,

    // shadow:
    shadow: true,
    shadowColor: '#000000',
    shadowOpacity: 0.8,
    shadowRadius: 10
  }

  selected = "Main"

  change(event: any) {
    this.selected = event.value;
  }

  openHall(event: any) {
    this.selected = event;
  }
  
  loading = false;
  data = {
    id: "481fb1d5-25cb-4dbd-582c-08dc4cd08f80",
    companyName: "ABANA KUMLAMA MAKİNALARI SAN.TİC.LTD.ŞTİ.",
    userType: "Özel Tasarım Stand Katılımcı",
    country: "TÜRKİYE",
    contactPerson: "RIDVAN TIĞLI",
    standNo: "H8-C137",
    phone: "+90 212 485 2804",
    webPage: "www.abanamakina.com",
    descriptionTr: "Abana Makina, 2008 yılında İstanbul’da döküm malzemeleri üretimi odaklı olarak faaliyetlerine başlamıştır. Başlangıçtaki kararlılık ve tutkuyla geçen yılların ardından, 2010 yılında stratejik bir adım atarak kumlama sektörüne giriş yapmış ve Kumlama Makinaları üretimine adım atmıştır. Bu dönemde sektöre sunduğu kaliteli ürünler ve yenilikçi yaklaşımıyla kısa sürede dikkatleri üzerine çekmiştir.\n\nAbana Makina, sektördeki yerini sağlamlaştırma ve gelişme yolunda kararlı adımlarla ilerlemektedir. Her geçen gün, tecrübe ve bilgi birikimini artırarak, müşterilerine en üst düzeyde hizmet sunma misyonunu sürdürmektedir. Müşteri memnuniyetini esas alan bir yaklaşımla, ihtiyaçlara yönelik çözümler sunmak ve bu doğrultuda ürün yelpazesini genişletmek temel hedeflerimiz arasındadır.\n\nAbana Makina olarak, endüstriyel kumlama makineleri alanında liderliği hedeflemekte ve bu doğrultuda sürekli olarak AR-GE çalışmalarına önem vermekteyiz. Müşterilerimizin talep ve beklentilerine en uygun çözümleri sunabilmek adına, teknolojiyi yakından takip etmekte ve ürünlerimizi sürekli olarak geliştirmekteyiz.\n\nKurulduğumuz günden bu yana geçen yıllarda, müşterilerimizin güvenini kazanarak sektördeki saygın konumumuzu sağlamlaştırdık. Abana Makina olarak, kaliteye olan bağlılığımızı ve müşteri odaklı yaklaşımımızı sürdürerek, sektördeki liderliğimizi pekiştirmeye ve global ölçekte tanınırlığımızı artırmaya devam edeceğiz",
    descriptionEng: "Abana Machinery was initially established in 2008 in Istanbul with the aim of manufacturing casting materials. In 2010, it took a new venture into the field of shot blasting and commenced production of Shot Blasting Machines. Continuously enhancing our quality and expanding our product range, Abana Machinery has steadily secured its position in the industry. Our rapidly growing expertise and experience in recommending and manufacturing shot blasting machines tailored to our customers' needs have further solidified our reputation.\n\nAbana Machinery has solidified its position in the industry and is advancing with determined steps towards growth. Each passing day, we increase our experience and knowledge to uphold our mission of providing top-notch services to our clients. Our core objectives include offering solutions tailored to the needs of our customers and continuously expanding our product range based on customer satisfaction.\n\nAt Abana Machinery, we aim to lead in the industrial shot blasting machine sector, prioritizing continuous research and development (R&D). We closely follow technological advancements to provide solutions that align with our customers' demands and consistently improve our products.\n\nOver the years since our establishment, we have earned the trust of our customers, solidifying our reputable position in the industry. Abana Machinery will continue to strengthen its leadership position and enhance global recognition by maintaining our commitment to qual",
    exhibitMobileResponses: [
      {
        prefix: "1",
        title: "Ham Maddeler , Birincil Metal Ürünler , Yarı Bitmiş Ürünler",
        engTitle: "Raw Materials, Primary Metal Products , Semi Finished Products"
      },
      {
        prefix: "1.1",
        title: "Ham Maddeler, İşletme (Yardımcı) Maddeleri",
        engTitle: "Raw Materials, Working Materials, Supplies"
      },
      {
        prefix: "1.3",
        title: "Granül, Toz, Pasta, Yün, Köpük Ürünler",
        engTitle: "Granulates, Powder, Paste, Wool, Foam"
      },
      {
        prefix: "1.7",
        title: "Toz Metalurjisi",
        engTitle: "Powder Metallurgy"
      },
      {
        prefix: "1.8",
        title: "Diğerleri",
        engTitle: "Others"
      }
    ]
  };

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit(): void { }

  sendMessageToFlutter() {
    const data = {
      param1: 'value1',
      param2: 'value2',
    };

    const trySendMessage = () => {
      // if (window.FlutterChannel && window.FlutterChannelReady) {
      //   window.FlutterChannel.postMessage(JSON.stringify(data));
      //   console.log('Message sent to Flutter');
      // } else {
      //   console.error('FlutterChannel is not available, retrying...');
      //   setTimeout(trySendMessage, 1000); // Retry after 1 second
      // }
    };

    trySendMessage();
  }

  send() {
    (window as any).Flutter.postMessage(JSON.stringify(this.data));
  }
}
