import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { InteractiveMapService } from 'src/app/services/interactive-map/interactive-map.service';
import { MaphilightComponent } from 'ng-maphilight';
import { ServiceResult } from 'src/app/models/responses/common/service-result';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-hall-three',
    templateUrl: './hall-three.component.html',
    styleUrls: ['./hall-three.component.scss'],
  })
  export class HallThreeComponent implements OnInit {
    @ViewChild(MaphilightComponent, { static: false }) maphilightComponent: MaphilightComponent;
    private _mobile = new BehaviorSubject<boolean>(false)
    mobile$ = this._mobile.asObservable()
  
    hidden = false
    config = {
      fade: true,
      alwaysOn: false,
      neverOn: false,
  
      // fill
      fill: true,
      fillColor: '#ffffff',
      fillOpacity: 0.4,
  
      // stroke
      stroke: true,
      strokeColor: '#4d0ec0',
      strokeOpacity: 1,
      strokeWidth: 1,
  
      // shadow:
      shadow: true,
      shadowColor: '#000000',
      shadowOpacity: 0.8,
      shadowRadius: 10
    }
  
    loading = true;
  
    // items: { type: string, location: string, information: string }[] = [
    //   { type: 'poly', location: '777,503,840,502,841,637,801,637,802,614,775,614,776,503', information: "First line2\nSecond line asdasddasd" },
    //   { type: 'rect', location: '842,502,916,637', information: "Osman" },
    // ];
    items: any[] = [];
  
  
    constructor(private interactiveMapService: InteractiveMapService) {
      this.getData();
    }
  
    getData() {
      this.loading = true;
      var request = {
        hall: "H3",
        expoId:environment.expoId
      };
      
      this.interactiveMapService.getCompaniesLocationInformation(request).subscribe(
        (response) => {
          const result = response as ServiceResult<any>;
  
          this.items = result.result;
  
          // console.log('items', result)
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  
    openTab(item: any) {
      window.open("https://www.ankiros.com/exhibitor/" + item.slug, '_blank');
    }
  
    ngOnInit(): void {
  
    }
  }
  