import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { IdRequest } from 'src/app/models/requests/common/id-request';
import { MaphilightComponent } from 'ng-maphilight';
import { SafeHtml } from '@angular/platform-browser';

interface Area {
  left: number;
  top: number;
  width: number;
  height: number;
  highlighted: boolean;
}
@Component({
  selector: 'app-map-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent
  implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(MaphilightComponent, { static: false }) maphilightComponent: MaphilightComponent;


  areas: Area[] = [
    { left: 0, top: 0, width: 50, height: 50, highlighted: true },
    // Define other areas as needed
  ];


  hidden = false
  config = {
    fade: true,
    alwaysOn: false,
    neverOn: false,

    // fill
    fill: true,
    fillColor: '#ffffff',
    fillOpacity: 0.4,

    // stroke
    stroke: true,
    strokeColor: '#4d0ec0',
    strokeOpacity: 1,
    strokeWidth: 1,

    // shadow:
    shadow: true,
    shadowColor: '#000000',
    shadowOpacity: 0.8,
    shadowRadius: 10
  }

  public coord: string;

  public backgroundImage: string;
  public safeHtml!: SafeHtml;
  public key: string;

  public locationResponse: any;

  public companyNameDisplay: string = '';

  public visible: boolean = false;

  imageUrl: string = '../../assets/Ankiros_Hall-2.jpg';



  constructor(
    private ref: ChangeDetectorRef,
  ) {
  }
  ngAfterViewChecked(): void { }

  ngAfterViewInit() {

  }

  highlightArea(event: MouseEvent) {
    const target = event.target as HTMLElement;
    target.classList.add('highlighted');
  }

  resetHighlight(event: MouseEvent) {
    const target = event.target as HTMLElement;
    target.classList.remove('highlighted');
  }

  ngOnInit(): void {
    this.visible = false;
    const idRequest = new IdRequest();
    idRequest.id = this.key;
    this.companyNameDisplay = 'asdsd';
    this.coord = '842,502,916,637'

    this.imageUrl = '../../assets/Ankiros_Hall-2.jpg';

    this.ref.detectChanges();

    this.visible = true;
    // setTimeout(() => {
    //   this.navigation();
    // }, 3000);
  }

  navigation() {
    const coordinates: string[] = this.coord.split(',');
    const left: number = parseInt(coordinates[2]);
    const top: number = parseInt(coordinates[3]);
    var element = document.getElementById('yourElement');
    element.style.left = left - 80 + 'px';
    element.style.top = top - 20 + 'px';
    var element = document.getElementById('companyName');
    element.style.left = left - 80 + 'px';
    element.style.top = top + 70 + 'px';
    window.scrollTo({
      top: top,
      left: left,
      behavior: 'smooth',
    });

    this.ref.detectChanges();
  }
}
