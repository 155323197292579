import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { InteractiveMapService } from 'src/app/services/interactive-map/interactive-map.service';
import { MaphilightComponent } from 'ng-maphilight';
import { ServiceResult } from 'src/app/models/responses/common/service-result';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-expo-area-hall-seven',
    templateUrl: './expo-area-hall-seven.component.html',
    styleUrls: ['./expo-area-hall-seven.component.scss'],
  })
  export class ExpoAreaHallSevenComponent implements OnInit {
    @ViewChild(MaphilightComponent, { static: false }) maphilightComponent: MaphilightComponent;
    private _mobile = new BehaviorSubject<boolean>(false)
    mobile$ = this._mobile.asObservable()
  
    hidden = false
    config = {
      fade: true,
      alwaysOn: false,
      neverOn: false,
  
      // fill
      fill: true,
      fillColor: '#ffffff',
      fillOpacity: 0.4,
  
      // stroke
      stroke: true,
      strokeColor: '#4d0ec0',
      strokeOpacity: 1,
      strokeWidth: 1,
  
      // shadow:
      shadow: true,
      shadowColor: '#000000',
      shadowOpacity: 0.8,
      shadowRadius: 10
    }
  
    loading = true;
    items: any[] = [];
    constructor(private interactiveMapService: InteractiveMapService) {
      this.getData();
    }
  
    getData() {
      this.loading = true;
      var request = {
        hall: "H7",
        expoId:environment.expoId
      };
      
      this.interactiveMapService.getCompanyMapInfo(request).subscribe(
        (response) => {
          const result = response as ServiceResult<any>;
  
          this.items = result.result;
  
          // console.log('items', result)
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  
    ngOnInit(): void {
    }
  
    openTab(item: any) {
      setTimeout(() => {
        (window as any).Flutter.postMessage(JSON.stringify(item));
      }, 1000);
    }
  }
  