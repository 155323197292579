import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let currentUser = JSON.parse(localStorage.getItem('session'));

    return new Promise((resolve) => {
      if (currentUser == null || currentUser == undefined) {
        this._router.navigate(['/login']);
        resolve(false);
      }
      resolve(true);
    });
  }
}
