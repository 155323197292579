import { Http, RequestOptions, Headers } from "@angular/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import { environment } from "../../../../environments/environment";

import "rxjs/add/observable/of";
import "rxjs/add/operator/map";

import "rxjs/add/operator/catch";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { throwError } from "rxjs";

export abstract class BaseService {
  protected defaultLanguage = "tr";
  protected client_id = "345e1927a3214f68abc79f2183837fd1";

  protected baseUrl = environment.apiEndpoint;

  constructor(protected http: HttpClient, protected httpRequest: Http) {}

  get(apiAddress: string, isAuthorizeRequest: boolean): Observable<any> {
    const control = localStorage.getItem("session");
    if (control === null || control === undefined) {
      var httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      var httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("session")),
        }),
      };
    }

    var httpSuccess = {};

    if (isAuthorizeRequest) {
      return this.intercept(
        this.http
          .get(this.baseUrl + apiAddress, httpOptions)
          .map((response) => {
            let data = response;
            if (data) {
              httpSuccess = data;
            }
            return httpSuccess;
          })
      );
    } else {
      return this.intercept(
        this.httpRequest.get(this.baseUrl + apiAddress).map((response) => {
          let data = response.json();
          if (data) {
            httpSuccess = data;
          }
          return httpSuccess;
        })
      );
    }
  }

  post(
    apiAddress: string,
    isAuthorizeRequest: boolean,
    parameter: any
  ): Observable<any> {
    var control = localStorage.getItem("session");
    if (control === null || control === undefined) {
      var httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      var httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("session")),
        }),
      };
    }

    var httpSuccess = {};

    if (isAuthorizeRequest) {
      return this.intercept(
        this.http
          .post(this.baseUrl + apiAddress, parameter, httpOptions)
          .map((response) => {
            let data = response;

            if (data) {
              httpSuccess = data;
            }
            return httpSuccess;
          })
      );
    } else {
      return this.intercept(
        this.httpRequest
          .post(this.baseUrl + apiAddress, parameter)
          .map((response) => {
            let data = response.json();
            if (data) {
              httpSuccess = data;
            }
            return httpSuccess;
          })
      );
    }
  }

  postFormData(
    apiAddress: string,
    isAuthorizeRequest: boolean,
    parameter: any
  ): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        encrypt: "multipart/form-data",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("session")),
      }),
    };

    var httpSuccess = {};

    return this.intercept(
      this.http
        .post(this.baseUrl + apiAddress, parameter, httpOptions)
        .map((response) => {
          let data = response;

          if (data) {
            httpSuccess = data;
          }
          return httpSuccess;
        })
    );
  }

  private intercept(observable: Observable<any>) {
    return observable.catch((error) => {
      var httpError: any = {};

      httpError.data = error;

      if (httpError.data.status === 401) {
        httpError.message =
          "Oturumunuz zaman aşımına uğradı lütfen çıkış yapıp tekrar giriş yapınız.";

        return throwError(httpError);
      } else {
        if (error._body !== undefined) {
          const x = JSON.parse(error._body);
          if (x.error === "801") {
            httpError.message = x.error_description;
          } else {
            httpError.message = "Beklenmedik bir hata ile karşılaşıldı.";
          }
        } else {
          httpError.message = error.error.message;
          return throwError(httpError);
        }
        return throwError(httpError);
        // return Observable.throw(httpError);
      }
    });
  }

  postWithoutToken(
    apiAddress: string,
    isAuthorizeRequest: boolean,
    parameter: any
  ): Observable<any> {
    var httpSuccess = {};

    if (isAuthorizeRequest) {
      return this.intercept(
        this.http.post(this.baseUrl + apiAddress, parameter).map((response) => {
          let data = response;
          if (data) {
            httpSuccess = data;
          }
          return httpSuccess;
        })
      );
    } else {
      return this.intercept(
        this.httpRequest
          .post(this.baseUrl + apiAddress, parameter)
          .map((response) => {
            let data = response.json();
            if (data) {
              httpSuccess = data;
            }
            return httpSuccess;
          })
      );
    }
  }
}
