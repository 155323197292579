import { BaseService } from '../rest/common/base.service';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class InteractiveMapService extends BaseService {
  constructor(httpClient: HttpClient, http: Http) {
    super(httpClient, http);
  }

  getCompaniesLocationInformation(request: any): Observable<any> {
    return this.post('api/v1.0/interactive/getCompaniesLocationInformation', true, request);
  }

  getCompanyMapInfo(request: any): Observable<any> {
    return this.post('api/v1.0/interactive/getCompanyMapInfo', true, request);
  }
}
