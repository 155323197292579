<div
  class="main-container"
  [dir]="dir"
  [ngClass]="{
    minisidebar: minisidebar,
    boxed: boxed,
    danger: danger,
    blue: blue,
    green: green,
    dark: dark
  }"
>
  <mat-toolbar color="primary" class="topbar telative">
    <div class="navbar-header">
      <a class="navbar-brand">
        <b> </b>
        <span fxShow="false" fxShow.gt-xs>
          <span style="color: white; cursor: pointer" (click)="goToHome()"
            >Hannover Messe Ankiros Fuarcılık A.Ş</span
          >
        </span>
      </a>
    </div>

    <form class="app-search" [ngClass]="{ 'show-search': showSearch }">
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="cl-srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
    <span fxFlex></span>
  </mat-toolbar>
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }"
  >
    <mat-sidenav-content class="page-wrapper">
      <app-breadcrumb></app-breadcrumb>
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
