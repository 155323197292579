import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from '../services/rest/common/account.service';

@Injectable()
export class TokenManager {
  private initializedDateMillis: any;

  private timer: any;

  private isTokenRefreshing = false;

  private sessionExpireTime: number = 30 * 60 * 1000;

  private sessionItem: any;

  public isInitialized: boolean = false;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private accountService: AccountService
  ) {}

  public initialize(session, username) {
    this.isInitialized = true;
    this.initializedDateMillis = new Date().getTime();

    this.setSession(session, username);

    this.sessionItem = JSON.parse(localStorage.getItem('session'));

    if (this.sessionItem == null || this.sessionItem === undefined) {
    }

    this.listenForKillingSession();
  }

  public onProgress() {
    if (!this.isInitialized) {
      return;
    }
    if (this.sessionItem == null || this.sessionItem === undefined) {
      return;
    }

    const processDateMillis = new Date().getTime();

    if (this.timer != null || this.timer !== undefined) {
      clearTimeout(this.timer);
    }

    if (
      this.initializedDateMillis + this.sessionItem.expires_in * 1000 * 0.9 <=
      processDateMillis
    ) {
      this.refreshToken();
    } else {
      this.listenForKillingSession();
    }
  }

  public resume() {
    if (this.isInitialized) {
      return;
    }

    if (this.timer != null || this.timer !== undefined) {
      clearTimeout(this.timer);
    }

    this.sessionItem = JSON.parse(localStorage.getItem('session'));

    if (this.sessionItem == null || this.sessionItem === undefined) {
      return;
    }

    const resumingDateTime = new Date();

    const resumingDateTimeInMillis =
      resumingDateTime.getTime() +
      resumingDateTime.getTimezoneOffset() * 60 * 1000;

    const expiresDate =
      new Date(this.sessionItem['.expires']).getTime() +
      resumingDateTime.getTimezoneOffset() * 60 * 1000;

    if (expiresDate < resumingDateTimeInMillis) {
    } else {
      this.isInitialized = true;
      this.initializedDateMillis = new Date().getTime();

      if (this.sessionItem == null || this.sessionItem === undefined) {
      }

      this.listenForKillingSession();
    }
  }

  private listenForKillingSession() {
    this.timer = setTimeout(
      () => this.openSessionDialog(),
      this.sessionExpireTime
    );
  }

  private openSessionDialog() {
    if (this.isTokenRefreshing) {
      return;
    }

    // TODO : BRSYNR when we add functionalty for session dialog ,uncomment this code.
    // var modalRef = this.modalService.showModal(SessionDialogComponent);

    // modalRef.result.then((result) => {
    //     if (result == null || result === undefined) {
    //         return;
    //     }

    //     if (result.result) {

    //         if (!this.isTokenRefreshing) {
    //             this.refreshToken();
    //         }

    //     }
    //     else {
    //         this.removeSession(true);
    //     }
    // });
  }

  private setSession(response, username) {
    localStorage.setItem(
      'session',
      JSON.stringify(response.result.accessToken.token)
    );
    if (username == null) {
      return;
    }

    localStorage.setItem('appUserId', response.appUserId);
    localStorage.setItem('displayName', response.displayName);
    localStorage.setItem('email', response.email);
    localStorage.setItem('username', response.username);
  }

  private refreshToken() {
    if (this.isTokenRefreshing) {
      return true;
    }

    this.isTokenRefreshing = true;

    this.accountService.refreshToken(this.sessionItem.refresh_token).subscribe(
      (response) => {
        this.initialize(response, null);
        this.isTokenRefreshing = false;
      },
      (error) => {
        this.isTokenRefreshing = false;
      }
    );
  }
}
