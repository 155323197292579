import { ExpoAreaHallEightComponent } from './mobile-map/expo-area-hall-eight/expo-area-hall-eight.component';
import { ExpoAreaHallFiveComponent } from './mobile-map/expo-area-hall-five/expo-area-hall-five.component';
import { ExpoAreaHallFourComponent } from './mobile-map/expo-area-hall-four/expo-area-hall-four.component';
import { ExpoAreaHallSevenComponent } from './mobile-map/expo-area-hall-seven/expo-area-hall-seven.component';
import { ExpoAreaHallSixComponent } from './mobile-map/expo-area-hall-six/expo-area-hall-six.component';
import { ExpoAreaHallThreeComponent } from './mobile-map/expo-area-hall-three/expo-area-hall-three.component';
import { ExpoAreaHallTwoComponent } from './mobile-map/expo-area-hall-two/expo-area-hall-two.component';
import { InteractivePlanComponent } from './interactive-plan/interactive-plan.component';
import { MobileMapComponent } from './mobile-map/mobile-main/mobile-main.component';
import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: '',
    component: InteractivePlanComponent,
  },
  {
    path: 'mobile',
    component: MobileMapComponent,
  },
  {
    path: 'mobile/hall-two',
    component: ExpoAreaHallTwoComponent,
  },
  {
    path: 'mobile/hall-three',
    component: ExpoAreaHallThreeComponent,
  },
  {
    path: 'mobile/hall-four',
    component: ExpoAreaHallFourComponent,
  },
  {
    path: 'mobile/hall-five',
    component: ExpoAreaHallFiveComponent,
  },
  {
    path: 'mobile/hall-six',
    component: ExpoAreaHallSixComponent,
  },
  {
    path: 'mobile/hall-seven',
    component: ExpoAreaHallSevenComponent,
  },
  {
    path: 'mobile/hall-eight',
    component: ExpoAreaHallEightComponent,
  },
];
