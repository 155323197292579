<div class="image-container" *ngIf="!loading">
    <maphilight id="statesMap2" #maphilight2 [config]="config">
      <img src="../../../assets/Ankiros_Hall-3.jpg" #img2>
  
      <map #map2>
  
        <div *ngFor="let item of items">
          <area appAreaTooltip shape="{{item.type}}" coords="{{item.location}}" alt="" (click)="openTab(item)"
            [attr.data-title]="item.information" ng-click="clicked('h')">
        </div>
      </map>
    </maphilight>
  </div>
  <div style="display: flex;justify-content: center;" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>