import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAreaTooltip]'
})
export class AreaTooltipDirective {
  private tooltipElement: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    // Prevent the default browser tooltip from appearing
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'title');

    const title = this.elementRef.nativeElement.getAttribute('data-title');
    if (title) {
      this.showTooltip(title, event);
    }

    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');

  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip(title: string, event: MouseEvent) {
    this.tooltipElement = this.renderer.createElement('div');
    this.tooltipElement.classList.add('area-tooltip');
    this.tooltipElement.innerHTML = title.replace(/\n/g, '<br>'); // Replace newline character with <br> tag
    // this.tooltipElement.textContent = title;

    this.tooltipElement.style.top = event.pageY - 10 + 'px';
    this.tooltipElement.style.left = event.pageX + 'px';

    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
