import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthorizationManager {
    private userPermissions: any[];

    constructor(private cookieService: CookieService) {

        if (this.cookieService.check('userMenu')) {
            // this.userPermissions = JSON.parse(this.cookieService.get('userMenu'));
            this.userPermissions = JSON.parse(localStorage.getItem('userMenu'));
        }
        else {
            this.userPermissions = null;
        }
    }

    public setUserMenu(userMenu: any[]) {
        this.userPermissions = userMenu;

    }

    public hasPermission(name: string): boolean {
        if (this.userPermissions == null) {

            return false;
        }

        if (this.userPermissions.findIndex(x => x.functionName === name) > -1) {

            return true;

        }
        else {

            return false;
        }
    }
}