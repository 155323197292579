import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AccountService } from './services/rest/common/account.service';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AreaTooltipDirective } from './helpers/area-tooltip-directive';
import { AuthGuard } from './helpers/auth.guard';
import { AuthorizationManager } from './helpers/authorizationManager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  CommonModule,
} from '@angular/common';
import { DemoMaterialModule } from './demo-material-module';
import { ExpoAreaHallEightComponent } from './mobile-map/expo-area-hall-eight/expo-area-hall-eight.component';
import { ExpoAreaHallFiveComponent } from './mobile-map/expo-area-hall-five/expo-area-hall-five.component';
import { ExpoAreaHallFourComponent } from './mobile-map/expo-area-hall-four/expo-area-hall-four.component';
import { ExpoAreaHallSevenComponent } from './mobile-map/expo-area-hall-seven/expo-area-hall-seven.component';
import { ExpoAreaHallSixComponent } from './mobile-map/expo-area-hall-six/expo-area-hall-six.component';
import { ExpoAreaHallThreeComponent } from './mobile-map/expo-area-hall-three/expo-area-hall-three.component';
import { ExpoAreaHallTwoComponent } from './mobile-map/expo-area-hall-two/expo-area-hall-two.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { HallEightComponent } from './interactive-plan/hall-eight/hall-eight.component';
import { HallFiveComponent } from './interactive-plan/hall-five/hall-five.component';
import { HallFourComponent } from './interactive-plan/hall-four/hall-four.component';
import { HallSevenComponent } from './interactive-plan/hall-seven/hall-seven.component';
import { HallSixComponent } from './interactive-plan/hall-six/hall-six.component';
import { HallThreeComponent } from './interactive-plan/hall-three/hall-three.component';
import { HallTwoComponent } from './interactive-plan/hall-two/hall-two.component';
import { HttpModule } from '@angular/http';
import { InteractiveMapService } from './services/interactive-map/interactive-map.service';
import { InteractivePlanComponent } from './interactive-plan/interactive-plan.component';
import { MaphilightModule } from 'ng-maphilight';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MobileMapComponent } from './mobile-map/mobile-main/mobile-main.component';
import { NgModule } from '@angular/core';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SpinnerComponent } from './shared/spinner.component';
import { TestComponent } from './test/test.component';
import { TokenManager } from './helpers/tokenManager';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

export function authHttpServiceFactory(
  http: HttpClient,
  tokenManager: TokenManager
) {
  return {
    tokenGetter: () => {
      tokenManager.onProgress();
      return JSON.parse(localStorage.getItem('session'));
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    SpinnerComponent,
    AppBreadcrumbComponent,
    TestComponent,
    InteractivePlanComponent,
    AreaTooltipDirective,
    HallTwoComponent,
    HallThreeComponent,
    HallFourComponent,
    HallFiveComponent,
    HallSevenComponent,
    HallSixComponent,
    HallEightComponent,
    MobileMapComponent,
    ExpoAreaHallTwoComponent,
    ExpoAreaHallSixComponent,
    ExpoAreaHallSevenComponent,
    ExpoAreaHallFourComponent,
    ExpoAreaHallFiveComponent,
    ExpoAreaHallEightComponent,
    ExpoAreaHallThreeComponent
  ],
  imports: [
    MaphilightModule,
    BrowserModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    MatTabsModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
    }),
  ],
  exports: [],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    AccountService,
    TokenManager,
    AuthGuard,
    AuthorizationManager,
    InteractiveMapService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
