import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Http } from "@angular/http";

@Injectable()
export class AccountService extends BaseService {
  constructor(httpClient: HttpClient, http: Http) {
    super(httpClient, http);
  }

  login(request: any): Observable<any> {
    return this.post("api/v1.0/account/loginClient", true, request);
  }

  refreshToken(ticket: string): Observable<any> {
    return this.post(
      "token",
      false,
      "grant_type=refresh_token&client_id=" +
        this.client_id +
        "&refresh_token=" +
        ticket
    );
  }

  logout(request): Observable<any> {
    return this.post("api/v1.0/account/logoutClient", false, request);
  }

  getCurrentProfile(request): Observable<any> {
    return this.post("api/v1.0/appuser/getCurrentProfile", true, request);
  }

  updateProfile(request): Observable<any> {
    return this.post("api/v1.0/appuser/updateProfile", true, request);
  }
}
