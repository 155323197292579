<div>
  <div class="image-container" *ngIf="selected==='Main'">
    <maphilight id="statesMapMain" #maphilightMain [config]="config">
      <img src="../../../assets/Ankiros_Main.jpg" #imgMain>

      <map #mapMain>

        <area appAreaTooltip coords="711,664,771,545,411,471,339,582" shape="poly" alt="'Hall 2'"
          (click)="openHall('Hall2')" ng-click="clicked('h')">

        <area appAreaTooltip coords="397,638,632,693,507,927,258,866" shape="poly" alt="'Hall 3'"
          (click)="openHall('Hall3')" ng-click="clicked('h')">

        <area appAreaTooltip coords="476,366,862,440,810,552,411,469" alt="'Hall 4'" shape="poly"
          (click)="openHall('Hall4')" ng-click="clicked('h')">

        <area appAreaTooltip coords="934,578,1342,662,1297,791,881,701" alt="'Hall 5'" shape="poly"
          (click)="openHall('Hall5')" ng-click="clicked('h')">

        <area appAreaTooltip coords="956,457,1272,521,1229,637,900,574" alt="'Hall 6'" shape="poly"
          (click)="openHall('Hall6')" ng-click="clicked('h')">

        <area appAreaTooltip coords="1006,352,1112,370,1195,454,1177,500,956,457" alt="'Hall 7'" shape="poly"
          (click)="openHall('Hall7')" ng-click="clicked('h')">

        <area appAreaTooltip coords="625,282,915,336,864,438,567,379" alt="'Hall 8'" shape="poly"
          (click)="openHall('Hall8')" ng-click="clicked('h')">
      </map>
    </maphilight>
  </div>
  <app-expo-area-hall-two *ngIf="selected==='Hall2'"></app-expo-area-hall-two>
  <app-expo-area-hall-three *ngIf="selected==='Hall3'"></app-expo-area-hall-three>
  <app-expo-area-hall-four *ngIf="selected==='Hall4'"></app-expo-area-hall-four>
  <app-expo-area-hall-five *ngIf="selected==='Hall5'"></app-expo-area-hall-five>
  <app-expo-area-hall-six *ngIf="selected==='Hall6'"></app-expo-area-hall-six>
  <app-expo-area-hall-seven *ngIf="selected==='Hall7'"></app-expo-area-hall-seven>
  <app-expo-area-hall-eight *ngIf="selected==='Hall8'"></app-expo-area-hall-eight>
</div>